<template>
  <div>
    <el-container>
<!--      <tree-layout :tree-options="treeOption" :tree-data="treeData"  @tree-node-click="treeNodeClick"-->
<!--                   @include-down="includeDown" style="margin-right:5px;"></tree-layout>-->
      <div class="shutleBack">
        <div class="shutleTitle">组织架构</div>
        <div class="searchBox">
          <el-input
            placeholder="请输入部门名称"
            v-model="searchKey"
            clearable
            @input="handleSearch"
          ></el-input>
        </div>
        <div class="shutContent">
          <el-tree
            :data="componylist"
            node-key="id"
            ref="tree"
            :props="defaultProps"
            default-expand-all
            :default-checked-keys="defaultCheckedKeys"
            @node-click="treeNodeClick"
          >
            <template class="custom-tree-node" slot-scope="{ node }">
              <el-tooltip :content="node.label" placement="bottom">
                <span class="text" >{{ node.label }}</span>
              </el-tooltip>
            </template>
          </el-tree>
        </div>
      </div>
        <el-container>
          <el-main>
          <grid-head-layout
            ref="gridHeadLayout"
            :grid-head-btn="gridHeadBtn"
            :search-columns="leftSearchColumns"
            :deviceProtocol="deviceProtocol"
            @grid-head-search="leftGridHeadSearch"
            @grid-head-empty="leftGridHeadEmpty"
          ></grid-head-layout>
          <grid-layout
            class="departTable"
            ref="leftGridLayOut"
            :grid-row-btn="gridRowBtn"
            :table-options="tableOptions"
            :table-data="leftTableData"
            :table-loading="leftTableLoading"
            :data-total="leftPage.total"
            :page="leftPage"
            @page-current-change="leftOnLoad"
            @page-size-change="leftOnLoad"
            @page-refresh-change="leftOnLoad"
          >

          </grid-layout>
          </el-main>
        </el-container>
    </el-container>
  </div>
</template>

<script>

import {getDeptLazyTree, userDeptList} from "@/api/system/dept";
import
{
  pageDeptByRole,
  roleAllocation,
  addDeptByRole,
  removeDeptByRole,
} from "@/api/system/user";
import {getDeptTree} from "@/api/system/dept";
let baseUrl = '/api/sinoma-system/dict/dictionary?code='
import {mapGetters} from "vuex";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import TreeLayout from "@/views/components/layout/tree-layout";
import {deptChildTree} from "@/api/reportTemplate";

export default {

  name: "protocol",
  components: {
    GridLayout,
    HeadLayout,
    TreeLayout
  },
  props: {
    currentRole:{},
    disabledList:[],
  },
  data() {
    return {
      searchKey: '',
      oldData: {},
      leftTableLoading: true,
      rightTableLoading: true,
      leftTableData: [],
      rightTableData: [],
      deviceProtocol: this.verdict,
      leftQuery:{},
      rightQuery:{},
      query:{},
      getDeptTreeDicData:[],
      leftPage: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      rightPage: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      componylist:[],
      defaultProps: {
        children: "children",
        label: "fullName",
        value: "id",
      },

    };
  },
  computed: {
    ...mapGetters(["permission"]),
    leftSearchColumns() {
      return [
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.account"),
          prop: "account",
          cell: true,
          align: 'left',
          width: 150,
          overHidden: true,
        },
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") +  this.$t("cip.plat.sys.user.field.realName"),
          prop: "realName",
          cell: true,
          align: 'left',
          width: 150,
          overHidden: true,
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        menu: false,
        selection: true,
        reserveSelection: true,
        selectable: this.selectable,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.account"),
            prop: "account",
            cell: true,
            align: 'left',
            width: 150,
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
            cell: true,
            width: 150,
            align: 'center',
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.account"),
            prop: "account",
            cell: true,
            align: 'left',
            width: 150,
            overHidden: true,
          },
          {
            label: "年龄",
            prop: "age",
            cell: true,
            align: 'left',
            width: 150,
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.sex"),
            prop: "sex",
            span: 8,
            type: "select",
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict/dictionary?code=gender",
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.deptName"),
            prop: "deptName",
            cell: true,
            align: 'left',
            overHidden: true,
          },
        ],
      }
    },
    gridRowBtn() {
      return []
    }
  },
  gridHeadBtn() {
    return []
  },
  headBtnOptions() {
  },
  mounted() {

    this.getcomponylist()
  },
  methods: {
    handleSearch() {
      this.searchKey = this.searchKey.trim();
      this.$nextTick(() => {
        if (this.searchKey){
          this.componylist = this.filterTreeData(this.oldData, this.searchKey);
        } else {
          this.componylist = this.oldData;
        }
      });
    },
    filterTreeData(list, searchKey) {
      return list.reduce((acc, item) => {
        let children = item.children ? this.filterTreeData(item.children, searchKey) : [];
        if (item.fullName.includes(searchKey) || (children.length > 0)) {
          acc.push({
            ...item,
            children
          });
        }
        return acc;
      }, []);
    },
    selectable(row){
      return this.disabledList.indexOf(row.userId+""+row.deptId) === -1
    },
    getcomponylist() {
      deptChildTree().then((res) => {
        console.log("进行输出--------",res)
        this.componylist = [res.data.data];
        this.treeNodeClick(res.data.data);
        this.oldData = [res.data.data];
      })
    },

    confirmTheSubmission(){
      console.log("进行输出-----------")
      return this.$refs.leftGridLayOut.selectionList
    },
    includeDown(e) {
      this.leftPage.currentPage = 1;
      this.query.isInclude = e;
      this.leftOnLoad(this.leftPage);
    },
    /**
     * 部门树结构点击事件
     * @param node
     */
    treeNodeClick(node) {
      console.log("进行输出-----------",node)
      this.treeDeptId = node.id;
      this.leftPage.currentPage = 1;
      this.leftOnLoad(this.leftPage);
    },
    leftGridHeadSearch(query) {
      this.leftPage.currentPage = 1;
      this.leftOnLoad(this.leftPage, query);
    },
    leftGridHeadEmpty(query) {
      this.leftPage.currentPage = 1;
      this.leftOnLoad(this.leftPage, query);
    },
    leftOnLoad(page, params = {}) {
      this.param = params;
      this.leftQuery = params
      this.leftPage = page;
      this.leftTableLoading = true;
      this.leftQuery.deptId = this.treeDeptId
      userDeptList(page.currentPage, page.pageSize, Object.assign(params, this.leftQuery)).then(res => {
        const data = res.data.data;
        this.$refs.leftGridLayOut.$refs.grid.page.total = data.total;
        this.leftPage.total = data.total;
        this.leftTableData = data.records;
        this.leftTableLoading = false;
      });
    },
    findChildData(tree, childId) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.id === childId) {
          // 如果当前节点就是目标子节点，直接返回其数据
          return node;
        } else if (node.children) {
          // 否则递归查找当前节点的子节点
          const childData = this.findChildData(node.children, childId);
          if (childData) {
            // 如果找到了目标子节点的数据，返回该数据
            return childData;
          }
        }
      }
      // 没有找到目标子节点的数据，返回 null
      return null;
    },
  }
};
</script>
<style scoped lang="scss">
.btn{
  display: flex;
  align-items: center;
}
.shutleBack {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}
.shutleTitle {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}
.shutContent {
  height: 500px;
  overflow: auto;
}
::v-deep .departTable .avue-crud .el-table {
  height: calc(100vh - 441px) !important;
  max-height: calc(100vh - 441px) !important;
}
</style>
